<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="700px">
      <v-card>
        <v-card-title
          >รายการเอกสารที่ต้อง Resend <v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">send</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <br />
          <div class="text-center">
            <v-chip class="pt-4 pl-2 ma-2"
              ><p>
                <v-icon :color="color.theme">mdi-file</v-icon><b> ไฟล์ที่เลือก {{ count_file }} ไฟล์</b>
              </p></v-chip
            >
          </div>
          <v-list>
            <v-list-item v-for="item in showresend" :key="item.title">
              <v-list-item-action @mouseover="item.show_name = true" @mouseleave="item.show_name = false">
                <v-icon large color="primary">{{ item.file_type === "folder" ? "folder" : "mdi-file " }}</v-icon>
              </v-list-item-action>
              <v-badge :value="item.show_name" color="grey" :content="item.file_name" transition="slide-y-transition"></v-badge>
              <v-list-item-content>
                <v-list-item-title @mouseover="item.show_name = true" @mouseleave="item.show_name = false">
                  {{ item.file_name }}</v-list-item-title
                >
                <v-list-item-subtitle>
                  <v-progress-linear
                    v-if="check_show_progress === true && item.file_value !== 100 && item.file_value !== 500"
                    :value="item.file_value"
                    color="light-blue"
                  ></v-progress-linear>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-chip v-if="item.file_value !== 500" class="ma-2 font-weight-bold" :color="color.theme" label outlined>
                  {{ item.file_status }}
                </v-chip>
                <v-chip v-else class="ma-2 font-weight-bold" color="red" label outlined>
                  {{ item.file_status }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- ปุ่มก่อนกดยืนยันที่จะresendหรือกดปิดdialog -->
          <v-btn
            v-if="check_show_progress === false && check_show_button === false"
            color="red"
            outlined
            @click="$emit('closedialog'), $emit('closebuttonmulti')"
            >{{ $t("changeName.close") }}</v-btn
          >
          <v-btn
            :color="color.theme"
            v-if="check_show_progress === false && check_show_button === false"
            :dark="color.darkTheme"
            @click="fn_multiresend(), (check_show_button = true), (check_show_progress = true)"
            >resend</v-btn
          >
          <!-- resendเสร็จจะโชว์ปุ่มนี้เพื่อกดปิด-->
          <v-btn
            color="red"
            :disabled="check_dis_button"
            v-if="check_show_button === true"
            outlined
            @click="$emit('closedialogreload')"
            >{{ $t("dialogmultidelete.buttonclose") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
export default {
  mixins: [validationMixin],
  validations: {
    filename: { required },
  },
  props: ["show", "file", "taxid", "type_data"],
  data: function() {
    return {
      check_dis_button: true,
      check_show_progress: false,
      check_show_button: false,
      showresend: [],
      count_item: 0,
      count_file: 0,
      list_file: [],
      countshowresend: 0,
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          console.log("file", this.file);
        }
        return this.show;
      },
      set(value) {
        if (!value) {
        }
      },
    },
  },
  watch: {
    show: function(val) {
      if (val === true) {
        console.log(this.type_data);
        this.fn_loaddata();
        this.check_show_button = false;
        console.log(this.file);
      } else {
        this.check_show_progress = false;
        this.showresend = [];
        this.count_item = 0;
        this.count_file = 0;
      }
    },
  },
  methods: {
    fn_loaddata() {
      for (let i = 0; i < this.file.length; i++) {
        this.count_item++;
        // console.log(this.file);
        this.count_file++;
        // console.log("++++",this.file[i]);
        let datarestore = {};
        datarestore["file_name"] = this.file[i]["filename"];
        datarestore["file_id"] = this.file[i]["id"];
        datarestore["file_icon"] = "file";
        datarestore["file_status"] = "resend";
        datarestore["file_value"] = 0;
        datarestore["show_name"] = false;
        datarestore["file_type"] = "file";
        this.showresend.push(datarestore);
      }
      this.countshowresend = this.showresend.length;
    },
    // //ฟังก์ชั่นวนresend
    async fn_multiresend() {
      for (let i = 0; i < this.file.length; i++) {
        let objIndex = this.showresend.findIndex((obj) => obj.file_id === this.file[i].id);
        //เช็คเพื่อนวนส่งข้อมูล
        this.showresend[objIndex]["file_status"] = "กำลังส่งไฟล์";
        let payload = {
          resend_id: this.file[i]["id"],
        };
        console.log("payload", payload);
        await this.fn_resend(payload, this.file[i].id).then(async (res) => {
          console.log("res++++", res);
          if (res.status === "resend success") {
            // console.log("yess");
          } else {
            // console.log("nooo");
          }
        });
        if (this.file.length - i === 1) {
          this.check_dis_button = false;
        }
      }
    },

    //resend
    async fn_resend(payload, file_id) {
      console.log("fn_resend file_id", file_id);
      let objIndex = this.showresend.findIndex((obj) => obj.file_id === file_id);
      // let auth = await gbfGenerate.generateToken();
      if (this.type_data === "รายการไฟล์") {
        let result = await this.axios({
          method: "POST",
          url: process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/resend/forward_inbox",
          data: payload,
          // headers: { Authorization: auth.code },

          onUploadProgress: (e) => {
            if ((e.loaded / e.total) * 100 >= 95) {
              this.showresend[objIndex]["file_value"] = 95;
            } else {
              this.showresend[objIndex]["file_value"] = parseInt((e.loaded / e.total) * 100);
            }
          },
        });
        return new Promise((resolve, reject) => {
          try {
            if (result.data.status === "OK") {
              this.showresend[objIndex]["file_status"] = "resend สำเร็จ";
              this.showresend[objIndex]["file_value"] = 100;
              setTimeout(() => {
                resolve({ status: "resend success", msg: "OK", code: "" });
              }, 500);
            } else {
              console.log("result", result);
              this.showresend[objIndex]["file_status"] = result.data.errorMessage;
              this.showresend[objIndex]["file_value"] = 500;
              setTimeout(() => {
                resolve({ status: "resend denied", msg: ": ", code: "" });
              }, 500);
            }
          } catch (err) {
            setTimeout(() => {
              resolve({ status: "resend denied", msg: ": " + err, code: "" });
            }, 500);
            this.showresend[objIndex]["file_status"] = err;
            this.showresend[objIndex]["file_value"] = 500;
          }
        });
      } else {
        let result = await this.axios({
          method: "POST",
          url: process.env.VUE_APP_SERVICE_DOWNLOAD_API + "/api/resend/forward_inbox_summary",
          data: payload,
          // headers: { Authorization: auth.code },

          onUploadProgress: (e) => {
            if ((e.loaded / e.total) * 100 >= 95) {
              this.showresend[objIndex]["file_value"] = 95;
            } else {
              this.showresend[objIndex]["file_value"] = parseInt((e.loaded / e.total) * 100);
            }
          },
        });
        return new Promise((resolve, reject) => {
          try {
            if (result.data.status === "OK") {
              this.showresend[objIndex]["file_status"] = "resend สำเร็จ";
              this.showresend[objIndex]["file_value"] = 100;
              setTimeout(() => {
                resolve({ status: "resend success", msg: "OK", code: "" });
              }, 500);
            } else {
              console.log("result", result);
              this.showresend[objIndex]["file_status"] = result.data.errorMessage;
              this.showresend[objIndex]["file_value"] = 500;
              setTimeout(() => {
                resolve({ status: "resend denied", msg: ": ", code: "" });
              }, 500);
            }
          } catch (err) {
            setTimeout(() => {
              resolve({ status: "resend denied", msg: ": " + err, code: "" });
            }, 500);
            this.showresend[objIndex]["file_status"] = err;
            this.showresend[objIndex]["file_value"] = 500;
          }
        });
      }
    },
  },
};
</script>
